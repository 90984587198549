<template>
  <div class="partners-sec">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <div class="testimonial-title">
            <h2 class="heading">Customers</h2>
            <div class="partners-img">
              <ul>
                <li v-for="client in clientList" :key="client.name">
                  <router-link :to="{ name: 'Portfolio' }">
                    <img
                      :src="returnImage(client.image)"
                      :alt="`${client.name} - Countable's Customer`"
                    />
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api/utils";

export default {
  computed: {
    clientList() {
      return this.$store.state.clientList;
    }
  },
  methods: {
    returnImage(img) {
      const imgPath = img ? img.url : "";
      const host = API.getBaseURL();
      return `${host}${imgPath}`;
    }
  }
};
</script>

<style></style>
