<template>
  <div id="industries-sec" class="industries-sec">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 offset-lg-1">
          <h2 class="heading">Industries</h2>
          <p class="sub-content">
            Countable drives web technology innovation across a variety of
            industries.
          </p>

          <ul>
            <li>
              <router-link :to="navigateToCortico">
                <div class="industry-item" data-mixpanel="landing_industries_health_care">
                  <div class="ind-icon">
                    <img src="@/assets/media/landing/ind-icon1.png" alt />
                  </div>
                  <h3>Health Care</h3>
                  <p>
                    device cloud
                   EMR, patient engagement
                  </p>
                </div>
              </router-link>
            </li>

            <li>
              <router-link :to="navigateToPortfolio">
                <div class="industry-item" data-mixpanel="landing_industries_spatial_analysis">
                  <div class="ind-icon">
                    <img src="@/assets/media/landing/ind-icon2.png" alt />
                  </div>
                  <h3>
                    Spatial
                    analysis and experience
                  </h3>
                  <p>GIS,AR,VR</p>
                </div>
              </router-link>
            </li>

            <li>
              <router-link :to="navigateToPortfolio">
                <div class="industry-item" data-mixpanel="landing_industries_industrial">
                  <div class="ind-icon">
                    <img src="@/assets/media/landing/ind-icon3.png" alt />
                  </div>
                  <h3>Industrial</h3>
                  <p>
                    PLC,PLM,
                   MES,MRP
                  </p>
                </div>
              </router-link>
            </li>

            <li>
              <router-link :to="navigateToPortfolio">
                <div class="industry-item" data-mixpanel="landing_industries_public_sector">
                  <div class="ind-icon">
                    <img src="@/assets/media/landing/ind-icon4.png" alt />
                  </div>
                  <h3>Public sector</h3>
                  <p>
                    data and
                   process management
                  </p>
                </div>
              </router-link>
            </li>

            <li>
              <router-link :to="navigateToPortfolio">
                <div class="industry-item" data-mixpanel="landing_industries_retail">
                  <div class="ind-icon">
                    <img src="@/assets/media/landing/ind-icon5.png" alt />
                  </div>
                  <h3>Retail</h3>
                  <p>
                    customer
                    journey management
                  </p>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    navigateToVr() {
      return {
        name: "VirtualReality"
      };
    },
    navigateToPortfolio() {
      return {
        name: "Portfolio"
      };
    },
    navigateToCortico() {
      return {
        name: "Services",
        params: {
          service: "cortico"
        }
      };
    }
  }
};
</script>

<style></style>
