<template>
  <div>
    <Banner />
    <Industries />
    <OurWork />
    <Testimonials />
    <Partners />
    <ContactUs />
  </div>
</template>

<script>
import Banner from "@/components/Index/Banner";
import ContactUs from "@/components/Index/ContactUs";
import Industries from "@/components/Index/Industries";
import OurWork from "@/components/Index/OurWork";
import Partners from "@/components/Index/Partners";
import Testimonials from "@/components/Index/Testimonials";

export default {
  name: "Index",
  components: {
    Banner,
    ContactUs,
    Industries,
    OurWork,
    Partners,
    Testimonials
  },
  created() {
    this.$store.dispatch("fetchClientsAPI");
  }
};
</script>

<style></style>
