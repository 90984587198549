<template>
  <div class="banner">
    <img src="@/assets/media/landing/banner.png" alt />
    <div class="carousel-caption">
      <div class="carousel-caption-b">
        <div class="carousel-caption-c">
          <div class="container">
            <div class="row">
              <div class="col-lg-7 ml-auto">
                <h1>
                  Delivering the
                  <br />future of web technology <br />ahead of schedule
                </h1>
                <p>
                  Countable drives web technology innovation across a variety of
                  industries.
                </p>
                <a
                  class="lets-btn"
                  href="#contact-form"
                  data-mixpanel="landing_cta_lets_talk_headline"
                  >LET'S TALK</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <a
      href="#industries-sec"
      class="scroll-down wow fadeIn"
      style="visibility: visible; animation-name: fadeIn;"
    >
      <span></span>
      <img src="@/assets/media/landing/down-arrow.svg" alt />
    </a>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
