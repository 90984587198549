<template>
  <div id="contact-form" class="contact-sec">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <div class="contact-tilte">
            <h2>Contact</h2>
            <form
              id="countable-contact-form"
              action="https://formspree.io/clark@countable.ca"
              method="POST"
            >

              <div class="form-group">
                <input
                  name="name"
                  type="text"
                  class="form-control"
                  placeholder="Your name"
                />
              </div>

              <div class="form-group">
                <input
                  name="email"
                  type="email"
                  class="form-control"
                  placeholder="Your e-mail"
                />
              </div>

              <div class="form-group">
                <textarea
                  name="message"
                  type="text"
                  class="form-control"
                  placeholder="Type your message here"
                ></textarea>
              </div>

              <button class="form-btn" data-mixpanel="landing_contact_submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
