<template>
  <div id="our-work" class="work-sec">
    <div class="right-path">
      <img src="@/assets/media/landing/right-path.png" alt />
    </div>
    <div class="left-path">
      <img src="@/assets/media/landing/left-path.png" alt />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-10 offset-lg-1">
          <div class="work-title">
            <h2 class="heading">Our work</h2>
            <div class="row">
              <div class="col-md-6">
                <router-link :to="{ name: 'Portfolio' }">
                  <div class="work-info">
                    <img src="@/assets/media/landing/img1.png" alt="amn aboriginal mapping network" />
                  </div>
                </router-link>
              </div>

              <div class="col-md-6">
                <router-link :to="{ name: 'Portfolio' }">
                  <div class="work-info">
                    <img src="@/assets/media/landing/img2.png" alt="html comment box" />
                  </div>
                </router-link>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 cta-wrapper">
                <router-link :to="{ name: 'Portfolio' }">
                  <a
                    class="btn-cta btn-work-see-more"
                    href="./portfolio.html"
                    data-mixpanel="landing_see_more_cta"
                  >See More</a>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="stats-section" class="row mt">
        <div class="col-lg-10 offset-lg-1">
          <h2 class="heading">Stats</h2>
          <p class="sub-content">
            We are an 11-year-old web solutions company located In Vancouver, Canada,
            <br />with a remote team of developers and designers around the
            world.
          </p>
          <div class="row space">
            <div class="col-md-6">
              <div class="work-details">
                <span>5+</span>
                <p>
                  Number of countries we have passionate developers and
                  designers working from
                </p>
              </div>
            </div>

            <div class="col-md-6">
              <div class="work-details work-detail-b">
                <div class="t-info">
                  <span>17</span>
                  <p>Happy clients in</p>
                </div>
                <div class="b-info">
                  <span>5</span>
                  <p>industries</p>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="work-details">
                <span>+25</span>
                <p>
                  Number of start-to-finish
                  <br />delivered web solutions
                </p>
              </div>
            </div>

            <div class="col-md-6">
              <div class="work-details work-detail-d">
                <h1>Millions</h1>
                <p>
                  of daily visitors
                  <br />to our products
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
