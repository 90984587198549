<template>
  <div class="testimonial-sec">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 offset-lg-3">
          <div id="testi" class="testimonial-title">
            <h2 class="heading">Testimonials</h2>
          </div>
          <div class="testimonial-slider">
            <div class="quote-img">
              <img src="@/assets/media/landing/quote.svg" alt />
            </div>
            <div class="owl-theme">
              <carousel
                :per-page="1"
                :mouse-drag="true"
                :navigation-enabled="true"
                :navigation-next-label="rightNavigation"
                :navigation-prev-label="leftNavigation"
                :loop="true"
                :pagination-enabled="false"
              >
                <slide v-for="item in testimonialList" :key="item.id">
                  <TestimonialItem :testimonial="item" />
                </slide>
              </carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TestimonialItem from "./TestimonialItem";
import { Carousel, Slide } from "vue-carousel";
import { mapGetters } from "vuex";

export default {
  components: {
    TestimonialItem,
    Carousel,
    Slide,
  },
  created() {
    this.$store.dispatch("fetchTestimonialAPI");
  },
  computed: {
    ...mapGetters(["testimonialList"]),
    leftNavigation() {
      return "<button type='button' role='presentation' class='owl-prev'><i class='fas fa-angle-left'></i></button>";
    },
    rightNavigation() {
      return `<button type="button" role="presentation" class="owl-next"><i class="fas fa-angle-right"></i></button>`;
    },
  },
};
</script>

<style>
.owl-theme {
  padding: 2rem 1rem 0 1rem;
  border: 1px solid rgb(0, 0, 0, 0.1);
}
</style>
