<template>
  <div class="item">
    <div class="slide-img">
      <img :src="returnImage(testimonial.showcase_image)" alt />
    </div>
    <div class="slide-text">
      <p>{{ testimonial.testimony }}</p>
      <p>
        {{ testimonial.client_name }}
        <br />
        {{ client.name }}
      </p>
    </div>
    <div class="bootom-img">
      <!-- <img :src="testimonial.group_logo" alt /> -->
      <img :src="returnImage(client.image)" />
    </div>
  </div>
</template>

<script>
import API from "@/api/utils";
export default {
  props: {
    testimonial: { type: Object },
  },
  computed: {
    client() {
      return this.testimonial.client;
    },
  },
  methods: {
    returnTopImage() {
      return require(`@/assets/media/landing/t-img.png`);
    },
    returnImage(imgLoc) {
      const host = API.getBaseURL();
      return `${host}${imgLoc.url || ""}`;
    },
  },
};
</script>

<style>
.item {
  position: relative;
  margin: 0 1rem;
}
.bootom-img {
  position: relative;
  bottom: 0;
}
</style>
